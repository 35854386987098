import React from "react"
import { ErrorMessage, Formik, Form, Field } from "formik"
import { graphql, navigate } from "gatsby"

import BgImage from "../components/BgImage"
import Footer from "../components/Footer"
import Layout from "../components/Layout"
import Liner from "../components/Liner"
import MastWrap from "../components/MastWrap"
import PageHeading from "../components/PageHeading"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default function Home({ data }) {
  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 769px)`,
    },
  ]

  const ContactForm = () => (
    <Formik
      initialValues={{
        name: "",
        email: "",
        message: "",
      }}
      onSubmit={(values, actions) => {
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "contact", ...values }),
        })
          .then(() => {
            alert("Thanks! Your message has been sent.")
            actions.resetForm()
          })
          .catch(() => {
            alert("Error")
          })
          .finally(() => {
            actions.setSubmitting(false)
            navigate("/")
          })
      }}
      validate={values => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
        const errors = {}
        if (!values.name) {
          errors.name = "Name Required"
        }
        if (!values.email || !emailRegex.test(values.email)) {
          errors.email = "Valid Email Required"
        }
        if (!values.message) {
          errors.message = "Message Required"
        }
        return errors
      }}
    >
      {() => (
        <Form name="contact" data-netlify="true">
          <Field
            name="name"
            className="border-form font2"
            placeholder="Name"
          />
          <ErrorMessage name="name" />

          <Field
            name="email"
            className="border-form font2"
            placeholder="Email"
          />
          <ErrorMessage name="email" />

          <Field
            name="message"
            className="border-form font2"
            component="textarea"
            placeholder="Type your message here..."
            cols="40"
            rows="8"
          />
          <ErrorMessage name="message" />

          <div className="add-top-quarter">
            <button className="btn btn-otto btn-otto-color" type="submit">
              Send Message
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )

  return (
    <Layout>
      <MastWrap>
        <section>
          <div>
            <BgImage
              title="hero"
              fluid={sources}
              imgStyle={{ objectFit: "contain" }}
              height="100vh"
              overlayColor="#04040454"
            >
              <PageHeading heading="contact" />
            </BgImage>
          </div>
        </section>

        <section id="ajax-content" className="mastwrap spaced-left">
          <section className="sub-wrap add-top-half add-bottom-half">
            <div className="container">
              <div className="row">
                <article className="col-md-6 text-left contact-box add-top-half">
                  <h1 className="font2 dark">get in touch</h1>
                  <Liner />
                  <h5 className="font4 grey">
                    I aim to respond within 24 hours
                  </h5>

                  <div className="form-wrapper">
                    <div id="contact-form-wrap">
                      <div className="contact-item pad-common ">
                        <ContactForm />
                      </div>
                    </div>
                  </div>
                </article>
              </div>
              <div className="row">
                <article className="col-md-6 text-left contact-box add-top-half">
                  <h3 className="font4 grey">
                    Alternatively, contact me using one of the following links and let's have a chat!
                  </h3>
                  <br />
                  <p className="font4 text-center">
                    Tel: {
                      <span className="text-nowrap">
                        <a href="tel:+447803 903580">+44 7803 903580</a>
                      </span>
                    }
                  </p>
                  <br />
                  <p className="font4 text-center">
                    Email: {
                      <span className="text-nowrap">
                        <a href="mailto:helenridgewaymua@outlook.com">helenridgewaymua@outlook.com</a>
                      </span>
                    }
                  </p>
                  <br />
                  <p className="font4 text-center">
                    Instagram: {
                      <span className="text-nowrap">
                        <a href="https://www.instagram.com/helenridgewaymua/">@helenridgewaymua</a>
                      </span>
                    }
                  </p>
                </article>
              </div>
            </div>
          </section>
          <Footer />
        </section>
      </MastWrap>
    </Layout>
  )
}

export const query = graphql`
  query Contact {
    mobileImage: file(relativePath: { eq: "bg/11.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
    desktopImage: file(relativePath: { eq: "bg/11.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
  }
`
